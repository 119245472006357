import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

//Validators for the register

export function validateDni(control: AbstractControl){
    const dni = control.value;
    const dniRegex: RegExp = /^[0-9]+$/;
    if(!dniRegex.test(dni) || dni.length < 7){
        return { invalidDni: true };
    }
    return null;
}

export function validateDniProcedureNumber(control: AbstractControl){
    const dniProcedureNumber = control.value;
    const dniProcedureNumberRegex: RegExp = /^[0-9]+$/;
    if(!dniProcedureNumberRegex.test(dniProcedureNumber) || dniProcedureNumber.length < 7){
        return { invalidDniProcedureNumber: true };
    }
    return null;
}

export function validateEmail(control: AbstractControl){
    const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const email: string = control.value;
    if(!emailRegex.test(email)){
        return { invalidEmail: true }
    }
    return null;
}

export function checkSameEmails(form: FormGroup){
    const email = form.get('email')?.value;
    const confirmEmail = form.get('confirmEmail')?.value;
    if (email !== confirmEmail) {
        form.get('confirmEmail')?.setErrors({ emailMismatch: true });
    } else {
        form.get('confirmEmail')?.setErrors(null);
    }
}

export function validateSex(control: AbstractControl){
    const sex: string = control.value;
    if (!['M', 'F', 'O'].includes(sex.toUpperCase())) {
        return { invalidSex: true };
    }
    return null;
}


//Validators to place a bet
export function validateMultiplyTrinka(multiply: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const amount: number = control.value;
        if (amount % multiply !== 0) {
            return { invalidMultiply: true };
        }
        return null;
    }
}